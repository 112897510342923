@import url('https://fonts.googleapis.com/css?family=Shrikhand|Raleway');

.crmwidget{
    width: 50%;
    max-width: 1vw;
}
.table>:not(caption)>*>*{
    background-color: transparent;
    color: inherit;
    /* padding: inherit; */
}

.crmwidgetspace{
    padding-bottom: 1%;
    /* padding-right: 1%; */
    /* padding-left: 1%; */
    background-color: #6e5794;
    /* background-color: #0b0b0b; */
}

.plan{
    background-color: transparent;
    font-family: Raleway;
    border-color: transparent;
    color: white;
    width: 85%;
    font-size: 1.75em;
}

.plansubmit{
    background-color: transparent;
    position: absolute;
    right: 1%;
    top: 15%;
    font-family: raleway;
    color: white;
}

.date{
    font-family: raleway;
    color: white;
    width: fit-content;
    background-color: transparent;
    border-color: transparent;
}

.react-datepicker__input-container{
    width: fit-content;
}

.ntDate{
    font-size: .75rem;
}

.note{
    font-family: raleway;
    color: white;
}

.newnote{
    background-color: transparent;
    border-color: white;
    color: white;
    font-family: raleway;
}

/* .printbtn{
    background-color: transparent;
    font-family: raleway;
    color: gold;
    border-color: #ffd200;
    border-width: 1px;
    position: absolute;
    top: 25%;
    right: 5%;
} */

.printbtn{
    /* background-color: transparent;
    font-family: raleway;
    color: gold;
    border-color: #ffd200;
    border-width: 1px; */
    position: absolute;
    top: 20%;
    right: 1%;
    width:4vw;
}

.bt1{
    background-color: transparent;
    border-color: #ffd200;
    font-family: raleway;
    color: #ffd200;
    border-width: 1px;
}

.bt2{
    background-color: transparent;
    border-color: white;
    font-family: raleway;
    color: white;
    border-width: 1px;
}

.circle{
    display:block;
    width:6em;
    height:6em;
    border-radius:3em;
    border-color: #ffd200;
    font-size:10px;
    color:#fff;
    text-align:center;
    background:#000;
}

.triangle {
    width: 0;
    height: 0;
    font-size:10px;
    display: inline-block;
    /* text-align:end;    */
    vertical-align: text-bottom;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    background:#000;
    /* transform: rotate(180deg); */
    color: black;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    border-bottom: 60px solid #ffd200; /* Double the height for a 6em triangle */
}


.online{
    display:block;
    width:3em;
    height:3em;
    border-radius:3em;
    border-color: black;
    font-size:10px;
    color: black;
    text-align:center;
    background: greenyellow;
}

.offline{
    display:block;
    width:3em;
    height:3em;
    border-radius:3em;
    border-color: black;
    font-size:10px;
    color:#fff;
    text-align:center;
    background:red;
}

.view{
    display:block;
    width:6em;
    height:6em;
    border-radius:3em;
    border-color: #ffd200;
    font-size:10px;
    color:#fff;
    text-align:center;
    background:#ffd200;
}

.fullnote:hover td{
    background-color: #ffd200;
    color: white;
    border-color:white;
}

.fullnote:hover .bt2{
    background-color: white;
    color: #00ff00;
    border-color:white;
}

.fullnote:hover .bt1{
    background-color: white;
    color: #8542F2;
    border-color:white;
}