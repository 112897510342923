@import url('https://fonts.googleapis.com/css?family=Shrikhand|Raleway');

.accesstitle{
    /* Admin Access */


position: absolute;
top: 5%;
left: 0%;
right: 0%;

font-family: Shrikhand;
font-style: normal;
font-weight: normal;
font-size: 72px;
line-height: 105px;
text-align: center;

color: #FFD200;
}

 /* Works on Chrome, Edge, and Safari */
 *::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: black;
}

*::-webkit-scrollbar-thumb {
  background-color: gold;
  border-radius: 20px;
  border: 3px solid black;
}

.securetext{
position: absolute;
top: 30%;
background-color: black;
border: 2px solid rgba(255, 210, 0, 0.66);
box-sizing: border-box;
}

.securetext:focus{
    background-color: black;
    border-color: #FFD200;
}

.submit {

position: absolute;
top: 40%;
left: 0%;
right: 0%;
border: 1px solid #FFD200;
box-sizing: border-box;
text-align: center;
background-color: black;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .accesstitle {
        font-size: 2.5rem;
    }

    .securetext {
        top: 25%
    }
}