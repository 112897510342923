.description{
    background-color: transparent;
    font-family: Raleway;
    border-color: transparent;
    color: white;
    width: 75%;
    font-size: 1em;
}

.short{
    position: relative;
}

.meal{
    border-color: white;
    border-width: 1px;
    color: white;
    font-family: Raleway;
    background-color: transparent;
    position: absolute;
    width: 6%;
    top: 35%;
    right: 5%;
}

.mealbl{
    color:white;
    position: absolute;
    font-family:raleway;
    top: 30%;
    right: 5%;
}

.meald{
    color:white;
    position: absolute;
    font-family:raleway;
    top: 40%;
    right: 5%;
}

.cal{
    border-color: white;
    border-width: 1px;
    color: white;
    font-family: Raleway;
    background-color: transparent;
    position: absolute;
    width: 6%;
    top: 55%;
    right: 5%;
}

.calbl{
    color:white;
    position: absolute;
    font-family:raleway;
    top: 50%;
    right: 5%;
}

.lblsep{
    color:white;
    font-family:Shrikhand;
    padding-top: 1%;
}

.rinput{
    background-color: black;
    color: white;
    font-family: raleway;
}